import { initMixpanel, mixpanelInitialized } from './src/shared/utils/mixpanel';
import Sentry from './src/shared/utils/sentry';
import {
  checkAndClearOldCookies,
  isTrackingConsentTrue,
  setTrackingCookies,
} from './src/shared/utils/trackingParams';

export const onClientEntry = () => {
  Sentry.init();

  if (!mixpanelInitialized) {
    initMixpanel();
  }
};

export const onRouteUpdate = ({ location }) => {
  if (isTrackingConsentTrue()) {
    checkAndClearOldCookies();
    setTrackingCookies();
  }

  let lastCookie = document.cookie;
  // rename document.cookie to document._cookie, and redefine document.cookie
  const expando = '_cookie';
  let nativeCookieDesc = Object.getOwnPropertyDescriptor(
    Document.prototype,
    'cookie'
  );
  Object.defineProperty(Document.prototype, expando, nativeCookieDesc);
  Object.defineProperty(Document.prototype, 'cookie', {
    enumerable: true,
    configurable: true,
    get() {
      return nativeCookieDesc.get.call(this);
    },
    set(value) {
      nativeCookieDesc.set.call(this, value);
      // check cookie change
      let cookie = nativeCookieDesc.get.call(this);
      if (cookie !== lastCookie) {
        try {
          // dispatch cookie-change messages to other same-origin tabs/frames
          let detail = { oldValue: lastCookie, newValue: cookie };
          this.dispatchEvent(
            new CustomEvent('cookiechange', {
              detail: detail,
            })
          );
          channel.postMessage(detail);
        } finally {
          lastCookie = cookie;
        }
      }
    },
  });

  // listen cookie-change messages from other same-origin tabs/frames
  let channel;
  if (typeof BroadcastChannel !== 'undefined') {
    // Use BroadcastChannel if supported
    channel = new BroadcastChannel('cookie-channel');
    channel.onmessage = e => {
      lastCookie = e.data.newValue;
      document.dispatchEvent(
        new CustomEvent('cookiechange', {
          detail: e.data,
        })
      );
    };
  } else {
    // Fallback: Use localStorage events for cross-tab communication
    channel = {
      postMessage: message => {
        localStorage.setItem('cookie-channel', JSON.stringify(message));
        window.dispatchEvent(new Event('storage')); // Simulate a storage event
      },
      close: () => {
        // No cleanup needed for the fallback
      },
    };

    // Listen for storage events as a fallback
    window.addEventListener('storage', event => {
      if (event.key === 'cookie-channel' && event.newValue) {
        const data = JSON.parse(event.newValue);
        lastCookie = data.newValue;
        document.dispatchEvent(
          new CustomEvent('cookiechange', {
            detail: data,
          })
        );
      }
    });
  }
};
