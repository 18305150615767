import mixpanel from 'mixpanel-browser';

import { isTrackingConsentTrue } from './trackingParams';

const MIXPANEL_TOKENS = {
  development: process.env.GATSBY_MIXPANEL_DEV_TOKEN,
  staging: process.env.GATSBY_MIXPANEL_STAGING_TOKEN,
  production: process.env.GATSBY_MIXPANEL_PROD_TOKEN,
};

const environment =
  process.env.GATSBY_ACTIVE_ENV || process.env.NODE_ENV || 'development';

const mixpanelToken = MIXPANEL_TOKENS[environment];

export let mixpanelInitialized = false;

export const isMixpanelInitialized = location => {
  if (isTrackingConsentTrue() && !mixpanelInitialized) {
    mixpanel.init(mixpanelToken, {
      debug: environment === 'development',
    });

    mixpanel.track('View page', {
      'Page url': location.pathname,
    });

    mixpanelInitialized = true;
    return true;
  } else {
    return mixpanelInitialized && typeof mixpanel !== 'undefined';
  }
};

export const initMixpanel = () => {
  if (!mixpanelToken) return;

  if (typeof window !== 'undefined') {
    if (isMixpanelInitialized(window.location)) {
      return;
    }
  }

  if (isTrackingConsentTrue() && typeof window !== 'undefined') {
    mixpanel.init(mixpanelToken, {
      debug: environment === 'development',
    });

    mixpanelInitialized = true;
    console.info('Mixpanel initialized successfully.');
  } else {
    console.info('Tracking consent not granted or window is undefined.');
  }
};

// These functions and comments come from the Mixpanel technical documentation
// https://help.mixpanel.com/hc/en-us/articles/360001337043-Incremental-Super-Properties

// Wait until Mixpanel is loaded
// Alternatively you may use the loaded callback in the init() or set_config()
// https://github.com/mixpanel/mixpanel-js/blob/8b2e1f7b/src/mixpanel-core.js#L95
const waitUntilLoad = func => {
  if (mixpanel.__loaded) {
    func();
  } else {
    setTimeout(() => {
      waitUntilLoad(func);
    }, 10);
  }
};

const incrementLogic = (property, isOnce) => {
  const value = mixpanel.get_property(property);
  const update = {};
  if (value && typeof value === 'number') {
    update[property] = value + 1;
  } else {
    update[property] = 1;
  }
  if (isOnce) {
    mixpanel.register_once(property);
  } else {
    mixpanel.register(update);
  }
};

// Wrapper for the incremental event logic
const increment = (property, isOnce) => {
  waitUntilLoad(() => {
    incrementLogic(property, isOnce);
  });
};

//increment the user property and setup the event incremental property
const superIncrementLogic = (property, isOnce) => {
  increment(property, isOnce);
  if (!isOnce) {
    mixpanel.people.increment(property);
  }
};

//a wrapper which acts a mixpanelSuperRegister method accepting a 'property'
// isOnce is used to set a property Once and not again.
// it will check if the data is set internally and will set it
// if it's not, otherwise it will not trigger the action
export const mixpanelSuperRegister = (property, isOnce = false) => {
  waitUntilLoad(() => {
    superIncrementLogic(property, isOnce);
  });
};
